import { getStock,exportStock } from "./api.js";
import navList from "../navList";
import Util from "@/utils/utils";

export default {
  data() {
    return {
      expLoading:false,
      pageLoadFlag: false,
      navList: [],
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/stockCenter/myStock",
          name: "myStock",
          title: "库存流水"
        }
      ],
      pager: {
        count: 0,
        pageNo: 1,
        pageSize: 10,
        sortName: "",
        sortOrder: ""
      },
      count: {
        totalOccQty: 0,
        totalActQty: 0,
        totalAvaQty: 0
      },
      scroll: {
        x: "2600"
        // y: 400
      },
      columns: [
        {
          label: "产品型号",
          prop: "model",
          ellipsis: true,
          key: "model",
          sortable: 'custom',
          sortName: "p.model",
          width: 200
        },
        {
          label: "颜色",
          prop: "colour",
          ellipsis: true,
          key: "colour",
          width: 90
        },
        {
          label: "产品名称",
          prop: "productName",
          ellipsis: true,
          key: "productName",
          width: 250
        },
        {
          label: "仓库",
          prop: "warehouseName",
          ellipsis: true,
          key: "warehouseName",
          width: 180
        },
        {
          label: "库存状态",
          prop: "invStatusName",
          ellipsis: true,
          key: "invStatusName",
          width: 200
        },
        // {
        //   label: "质量等级",
        //   prop: "quvlitylv",
        //   ellipsis: true,
        //   key: "quvlitylv",
        //   width: 100
        // },
        {
          label: "补差类型",
          prop: "invStatusTypeName",
          ellipsis: true,
          key: "invStatusTypeName",
          width: 200
        },
        // {
        //   label: "单据编号",
        //   prop: "orderNum",
        //   ellipsis: true,
        //   key: "orderNum",
        //   width: 120
        // },
        {
          label: "批次",
          prop: "invBatchName",
          ellipsis: true,
          key: "invBatchName",
          width: 90
        },
        {
          label: "批次创建时间",
          prop: "createDate",
          ellipsis: true,
          key: "createDate",
          width: 250
        },
        {
          label: "供应商",
          prop: "supplierName",
          ellipsis: true,
          key: "supplierName",
          width: 100
        },
        {
          label: "销售组织",
          prop: "orgName",
          ellipsis: true,
          key: "orgName",
          width: 200
        },
        {
          label: "单据编号",
          prop: "stvNum",
          ellipsis: true,
          key: "stvNum",
          width: 200
        },
        {
          label: "事务类型",
          prop: "transactionType",
          ellipsis: true,
          key: "transactionType",
          width: 200
        },
        {
          label: "库存数量",
          prop: "btransQty",
          ellipsis: true,
          align: "right",
          key: "btransQty",
          width: 200
        },
        {
          label: "价格",
          prop: "price",
          align: "right",
          ellipsis: true,
          key: "price",
          width: 90
        },
        {
          label: "单据时间",
          prop: "transactionTime",
          ellipsis: true,
          key: "transactionTime",
          width: 200
        },
        {
          label: "产品编码",
          prop: "productCode",
          ellipsis: true,
          key: "productCode",
          sortable: 'custom',
          sortName: "l.product_code",
          width: 160
        },
        {
          label: "操作员",
          prop: "operator",
          ellipsis: true,
          key: "operator",
          width: 150
        },
        {
          label: "数据来源",
          prop: "dataSourceValue",
          ellipsis: true,
          key: "dataSource",
          width: 150
        }
      ],
      data: [],
      terms: {},
      showLoading:false
    };
  },
  computed: {
    // 实际统计
    totalBig: function() {
      return this.data.reduce((sum, item) => {
        return sum + (parseInt(item.totalBig) || 0);
      }, 0);
    },
    // 可用统计
    bigQty: function() {
      return this.data.reduce((sum, item) => {
        return sum + (parseInt(item.bigQty) || 0);
      }, 0);
    },
    // 预留统计
    bigQtyLock: function() {
      return this.data.reduce((sum, item) => {
        return sum + (parseInt(item.bigQtyLock) || 0);
      }, 0);
    }
  },
  created() {
    this.navList = navList.nav1;
    this.getData();
  },
  methods: {
    searchFun(arg) {
      this.terms = arg[1];
      this.pager.pageNo = 1;
      this.getData();
    },
    //排序change事件
    onTableChange(sorter) {
      const sortName = this.columns.filter(it=>it.prop==sorter.prop)
      if(sortName.length<1){
        return
      }
      if (sorter && sorter.order) {
        this.pager.sortOrder =
            sorter.order == "ascending"
                ? "asc"
                : sorter.order == "descending"
                ? "desc"
                : "";
        this.pager.sortName = sortName[0].sortName;
      } else {
        this.pager.sortOrder = "";
        this.pager.sortName = "";
      }
      this.getData();
    },
    exportFile(){
      this.expLoading = true;
      const data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        _loading: true,
        terms: {
          supplierName: "",
          orgName: "",
          invStatusId: "",
          gicWarehouseType: "",
          stvNum: "",
          invStatusType: "",
          productName: "",
          operator: "",
          transactionType: "",
          colour: "",
          productCode: "",
          warehouseId: "",
          model: "",
          dataSource: "",
          startDate: "",
          endDate: "",
          ...this.terms
        },
        page: {
          pageNo: 1,
          pageSize: 300000,
          sortName: this.pager.sortName,
          sortOrder: this.pager.sortOrder
        }
      };
      exportStock(data).then(res => {
        Util.blobToJson(res.data).then(content=>{
          if(content&&content.msg=='success'){
            this.$message.success('下载成功，请到下载中心查看!')
          }
        }).catch(err=>{
          Util.downloadFile(res.data,'库存流水.xls')
        }).finally(()=>{
          this.expLoading = false;
        })
      }).catch(err=>{
        this.expLoading = false;
      });
    },
    getData() {
      const data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        _loading: true,
        terms: {
          supplierName: "",
          orgName: "",
          invStatusId: "",
          gicWarehouseType: "",
          stvNum: "",
          invStatusType: "",
          productName: "",
          operator: "",
          transactionType: "",
          colour: "",
          productCode: "",
          warehouseId: "",
          model: "",
          dataSource: "",
          startDate: "",
          endDate: "",
          ...this.terms
        },
        page: {
          pageNo: this.pager.pageNo,
          pageSize: this.pager.pageSize,
          sortName: this.pager.sortName,
          sortOrder: this.pager.sortOrder
        }
      };
      this.showLoading = true
      getStock(data).then(res => {
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        this.data = data.data || [];
        this.showLoading = false
        this.pager.count = data.page.totalCount;
      });
    }
  }
};
