import dmshttp from "@/utils/dmsrequest";

//库存流水
export function getStock(data) {
    return dmshttp({
        url: "findAllInventoryLog",
        data
    });
}
export function exportStock(data) {
    return dmshttp({
        url: "exportAllInventoryLog&type=1",
        data,
        responseType: 'blob'
    });
}